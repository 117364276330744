@import 'src/assets/styles/variables.scss';

.dx-fileuploader {
    border-radius: 5px;
}

.dx-fileuploader-dragover {
    .dx-fileuploader-input {
        padding: 92px 3px;
    }
}

.dx-fileuploader-input {
    padding: 82px 0;
}

.dx-fileuploader-container {
    border: 1px dashed $primary-color;
    display: block;

    .dx-fileuploader-content {
        display: block;
        height: 100%;
    }
}

.dx-fileuploader-input-wrapper::before {
    display: none;
}

.dx-fileuploader-input-wrapper::after {
    display: none;
}

.dx-fileuploader-input-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-end;
    height: 100%;
    position: relative;
}

.dx-fileuploader-input-container {
    height: 100%;
    position: absolute;
    top: 0;

    .dx-fileuploader-input-label {
        bottom: 1.2rem;
        position: absolute;
        height: unset;
        width: 100%;
        top: unset;
        text-align: center;
        background-clip: content-box;
        font-size: 1.2rem;
    }
}

.dx-fileuploader-button {
    position: relative;
    bottom: 3.2rem;
    z-index: 3;
}
