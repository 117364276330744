@import '../variables.scss';

// .dx-popup-title {
//     border-bottom: 3px solid $grey-f9;
//     color: $secondary-color;

//     &.dx-toolbar {
//         padding: 18px 33px;
//     }
// }

// .dx-popup-content {
//     padding: 0px;
// }

// .dx-popup-bottom {
//     &.dx-toolbar {
//         padding: 18px 33px;
//         border-top: 3px solid $grey-f9;

//         .dx-toolbar-items-container {
//             height: 45px;
//         }

//         .dx-toolbar-item {
//             padding: 0 15px 0 0;
//         }
//     }
// }

.history-popup {
    .dx-popup-title {
        border-bottom: unset;
        .dx-toolbar-label {
            font-size: 32px;
            font-weight: bold;
            color: #002f63;
        }

        .dx-icon-close {
            opacity: 0.5;
            font-size: 2.1rem;
            color: #002f63;
        }
    }
}

.query-popup {
    .dx-popup-content {
        padding: 0;
    }
}