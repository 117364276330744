@import '../variables.scss';

.dx-dropdownlist-popup-wrapper {
    .dx-empty-message,
    .dx-list-item {
        // color: $black-3-a7;
        position: relative;

        &:nth-child(odd) {
            // background-color: $grey-fcbd;
        }
    }

    .dx-list:not(.dx-list-select-decorator-enabled) .dx-list-item-content {
        padding: 10px 25px;
    }
}

.dx-dropdowneditor-button {
    color: #46a4d4;
}
