@import '../variables.scss';



.darkMode  #grid-container .dx-header-row .fixed-grid-col {
    background-color: #353a61 !important;
}

.lightMode  #grid-container .dx-header-row .fixed-grid-col {
    background-color: #f5f7fd !important;
}

#grid-container {
    .dx-header-row {
        height: 9rem;
        .fixed-grid-col {
            padding-top: 10.5rem !important;
            padding-bottom: 0px!important;
            font-weight: 500;
            background-color: #f5f7fd !important;
        }
    }

    .dx-header-row .dx-datagrid-action  {
        padding-top: 1.5rem;
    }

    .dx-header-row .dx-datagrid-drag-action {
        padding-top: 1.5rem;
    }

    .fixed-grid-col:not(:first-child) {
        border-right: 1px solid #a4c0ea !important;
    }

    .dx-datagrid-rowsview .dx-row>td {
        overflow: hidden !important;
        text-overflow: ellipsis !important;
    }
}

#app-grid-container {
    .dx-scrollable-container {
        &::-webkit-scrollbar {
            width: 10px;
            height: 10px;
        }

        /* Track */
        &::-webkit-scrollbar-track {
            background: #ededed;
        }

        /* Handle */
        &::-webkit-scrollbar-thumb {
            background: #c1c1c1;
        }

        /* Handle on hover */
        &::-webkit-scrollbar-thumb:hover {
            background: rgb(146, 146, 146);
        }
    }

    .dx-datagrid {
        background-color: unset;
    }

    .dx-toolbar {
        background-color: unset;

        .dx-datagrid-toolbar-button {
            border: unset;
        }

        .dx-button-content {
            color: rgba(51, 51, 51, 0.7);
        }
        .dx-button-mode-contained {
            background-color: unset;
        }
    }

    .dx-datagrid-headers {
        border: unset;
        border-bottom: 2px solid #eee;
        box-shadow: 0 2px 10px 0 rgb(0 0 0 / 8%);
        background-color: #ffffff;
    }

    .dx-header-row {
        height: 4rem;
        .fixed-grid-col {
            padding-top: 47px;
            padding-bottom: 0px;
            font-weight: 300;
        }
        td {
            text-transform: uppercase;
            border: 0;
            height: 35px;
        }

        .not-first-topic {
            border-left: 1px solid #bbb;
        }

        .header-topic-col {
            color: #333;
            font-weight: 700;
            vertical-align: middle;
        }
    }

    .fixed-grid-col:not(:first-child) {
        border-right: 2px solid #bbb;
    }

    .fixed-grid-col {
        font-weight: 700;
    }

    .fixed-grid-col.name-col {
        font-weight: unset;
    }

    .dx-datagrid-rowsview {
        border: 0;
        background-color: #ffffff;

        .dx-row {
            border-bottom: 2px solid #eee;
            height: 55px;
        }

        td {
            vertical-align: middle;
        }

        .not-first-topic {
            border-left: 1px solid #bbb;
        }
    }

    td {
        border: 0;
        padding-left: 10px;
    }

    .dx-datagrid-borders > .dx-datagrid-filter-panel {
        border: unset;
    }

    .dx-page-size,
    .dx-page {
        font-size: 1.6rem;
        &.dx-selection {
            background-color: #c8d9e9;
            color: $primary-color;
            font-weight: bold;
        }
    }
}
