// ------------ Menu Vars
$space-between-menu-items: 85px;
$nav-menu-height: 100vh;
$nav-menu-width: 82px;
$nav-menu-width-low-res: 72px;

// ------------ Header Vars
$header-height: 0px;
$page-width: calc(100% - (#{$nav-menu-width}));
$page-width-low-res: calc(100% - (#{$nav-menu-width-low-res}));

//------------- Page Layout
$tabs-height: 45px; // ------------ Pages Vars
$table-header-height: 58px;
$page-padding-bottom: 61px;
$page-padding-left: 20px;
$page-padding-right: 20px;
$page-padding-top: 15px;
$sticky-left-app-infos: 345px;
$fixed-footer-height: 80px;
$componentContainerMarginBottom: 6px;
$componentContainerMarginTop: 6px;
$componentContainerHeight: calc(100% - 12px);

//------------- Colors
$primary-color: #002a63;
$primary-color-light: #003680;
$nice-blue: #0d59ad;
$secondary-color: #238acb;
$cornflower-blue: #5fa3d0;
$error-color: #d93943;
$black-3: #333333;
$black-39: #393939;
$black-3-a7: rgba($black-3, 0.7);
$greyish: #a6a6a6;
$pinkish-grey: #c8c8c8;
$grey-e: #eeeeee;
$pale-grey-three: #f2f6fa;
$pale-grey-four: #f9fbfd;
$grey-fcbd: #fcfbfd;
$grey-f8: #f8f8f8;
$grey-f9: #f9f9fc;
$grey-2: #eceef5;
$light-blue-grey: #cce1ef;
$slate: #47576b;

//------------- Applications Edition & View
$app-form-margin-left: 10px;
$app-form-margin-right: 10px;
$app-form-margin-top: 6px;
$app-form-content-height: calc(100vh - #{$app-form-margin-top});
$app-form-height: calc(100vh - #{$app-form-margin-top});
$app-form-width: calc(100% - #{$sticky-left-app-infos});
//------------- Applications Edition
