.dx-menu-base .dx-menu-item-content {
    padding: 0;
    // color: #47576b;
    font-size: 12px;
    display: flex;
    align-items: center;

    em {
        opacity: 0.6;
        margin-right: 5px;
        line-height: 15px;
    }

    > div {
        padding: 7px 10px;
        width: 100%;
        display: flex;
    }

    span {
        margin-right: 10px;
    }

    .map {
        border-top: 1px solid rgba(71, 87, 107, 0.1);
        border-bottom: 1px solid rgba(71, 87, 107, 0.1);
    }

    .quick-view {
        font-weight: bold;
    }

    .topic {
        padding-left: 30px;
        display: flex;
        justify-content: space-between;

        .angle-right {
            font-size: 1.7rem;
        }
    }
    .completions {
        margin-left: auto;
        margin-right: 0;
        width: 14px;
        text-align: center;
    }

    em.fa-check-circle {
        color: $primary-color;
    }

    em.fa-times {
        color: $error-color;
    }

    em.grey {
        color: #b6b6b6;
    }
}

.dx-menu-base .dx-menu-item.dx-state-disabled {
    opacity: 1;
    cursor: unset;
}

.dx-submenu .dx-submenu .dx-menu-item.dx-state-disabled {
    opacity: 0.5;
}

.dx-menu-base .dx-menu-item .dx-menu-item-content {
    min-width: 155px;
}

.dx-menu-items-container .dx-menu-item-wrapper:nth-child(5) {
    cursor: default;
}

.dx-context-menu {
    border-radius: 4px;
    .dx-submenu {
        border-radius: 4px;
    }
}
