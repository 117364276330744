@import '../variables.scss';

.dx-button {
    border-radius: 0.5rem !important;

    &.button-add-new {
        font-size: 1.4rem;
        color: #46a4d4;
        border: none;

        .dx-button-content {
            padding: 0;
        }

        .dx-icon {
            font-size: 1.4rem;
            color: rgba(70, 164, 212, 0.5);
        }
    }

    &.button-icon {
        border: none;

        .dx-icon {
            color: #46a4d4;
        }
    }

    &[aria-label="http(s)://*"]
    {
        border-top: none;
        border-left: none;
        border-bottom: none;
        border-right: 1px solid $grey-2;
        border-radius: 0;
        margin: 0;
        height: 100%;
        background-color: $grey-f8;
        color: $black-3-a7;
        font-size: 1.6rem;
        opacity: 1;

        > .dx-button-content {
            padding-left: 1.2rem;
            padding-right: 0.9rem;
        }

        &.dx-state-disabled {
            .dx-button-text {
                opacity: 1;
            }
        }
    }

    &[aria-label='far fa-search'] {
        border-top: none;
        border-left: none;
        border-bottom: none;
        border-right: 2px solid $grey-2;
        border-radius: 0;
        margin: 0;
        // height: 100%;
        // background-color: $grey-f8;
        color: $black-3-a7;
        font-size: 1.7rem;
        opacity: 0.4;
        width: 60px;

        > .dx-button-content {
            padding-left: 17px;
            padding-right: 17px;
        }

        &.dx-state-disabled {
            .dx-button-text {
                opacity: 1;
            }
        }
    }
}

// .dx-button-mode-contained {
//     &.dx-button-default {
//         background-color: rgba($color: white, $alpha: 0.1);
//         border-color: white;

//         &.dx-state-hover,
//         &.dx-state-focused {
//             border-color: white;
//         }

//         .dx-icon {
//             color: rgba($color: white, $alpha: 0.5);
//         }
//     }
// }
.addAppButton {
    &.dx-button-mode-contained.dx-button-default .dx-icon {
        color: white;
        opacity: 1;
    }
    span {
        line-height: unset;
    }
}
.exportButton {
    &.dx-button-mode-contained.dx-button-default .dx-icon {
        color: $primary-color;
        opacity: 1;
    }
    span {
        line-height: unset;
    }
}

.dx-button-mode-outlined {
    &.dx-button-default {
        border-color: $primary-color;
        color: $primary-color;
    }
}

.dx-button-text {
    font-weight: 500;
}

.dashboard-btn {
    &.dx-button {
        border-radius: 50%;
    }

    &.dx-button-mode-outlined {
        border-color: rgba($color: $cornflower-blue, $alpha: 0.3);

        .dx-icon {
            font-size: 10px;
            color: white;
        }
    }

    &.dx-button-has-icon {
        .dx-button-content {
            padding: 0;
        }
    }
}

.query-manager-container {
    .dx-button {
        .dx-icon {
            color: #002a63;
            opacity: 0.5;
        }

        &.apply-button,
        &.save-button {
            background-color: #002a63;
            .dx-icon {
                color: white;
            }
        }
    }

    .list-wrapper {
        .dx-button {
            background-color: transparent;
            .dx-button-content {
                padding: 0;
                display: flex;
                align-items: center;
                justify-content: center;
            }
        }

        .dx-button .dx-icon {
            font-size: 22px;
            display: flex;
            align-items: center;
            justify-content: center;
            color: white;
        }

        .unactive-query .dx-button .dx-icon {
            color: #002a63;
        }
    }
}
