@import '../variables.scss';

.tooltip-user {
    &.dx-tooltip-wrapper {
        .dx-overlay-content {
            border: none;
            color: white;
            background-color: $primary-color;
            font-size: 10px;

            .dx-popup-content {
                padding: 0;
            }
        }

        &.dx-popover-wrapper {
            .dx-popover-arrow:after {
                background: $primary-color;
                border: none;
            }
        }
    }
}

.dx-tooltip-wrapper {
  .dx-popover-arrow {
    display: none;
  }

  .dx-overlay-content {
    border: none !important;
    -webkit-box-shadow: 0 0 6px rgba($color: #515151, $alpha: 0.5) !important;
    box-shadow: 0 2px 4px rgba($color: #515151, $alpha: 0.5) !important;
    border-radius: 5px !important;
  }
}

.tooltip-desc {
    &.dx-tooltip-wrapper .dx-overlay-content .dx-popup-content {
        width: 300px;
        text-align: justify;
        white-space: pre-wrap;
    }
}

.history-tooltip {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}
