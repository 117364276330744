@import '../variables.scss';

.dx-accordion-wrapper {
    padding-right: 0rem !important;
}

.dx-accordion-item {
    border-radius: 0.5rem !important;
    margin-bottom: 0.8rem !important;
    min-height: 5rem !important;

    &.dx-state-active:not(.dx-accordion-item-opened) > .dx-accordion-item-title {
        background-color: transparent !important;
    }

    &:last-child {
        margin-bottom: $app-form-margin-top !important;
    }
}

.dx-accordion-item-title {
    font-size: 2.4rem !important;
    font-weight: 900 !important;
    padding: unset !important;
    border-radius: 0.5rem !important;

    &.accordion-item-disabled::before {
        opacity: 0.5 !important;
    }

    &.accordion-item-disabled {
        .accordion-item span,
        .step-icon {
            opacity: 0.5 !important;
        }
    }

    &:before {
        font-size: 1.6rem !important;
        color: #46a4d4 !important;
      
        line-height: 4rem !important;
        padding: 0 2rem !important;
    }

    .dx-accordion-item-title-caption {
        display: flex !important;
        align-items: center !important;
        padding: 0 2rem !important;
        min-height: 5rem !important;
       

        .accordion-item {
            display: flex !important;
            align-items: center !important;
            justify-content: space-between !important;
            align-content: stretch !important;
            font-family: "CircularStd", sans-serif !important;
            font-size: 1.6rem !important;
            font-weight: bold !important;
            font-stretch: normal !important;
            font-style: normal !important;
            line-height: normal !important;
            letter-spacing: normal !important;
            text-align: left !important;
            text-transform: uppercase !important;
        }

        .dx-icon {
            margin-right: 1.6rem !important;
        }
    }
}

.dx-accordion-item-body {
    width: 100% !important;

    &:focus {
        outline: unset !important;
    }
}

.platform-icon {
    font-size: 1.1rem !important;
    color: white !important;
    background-color: #07478d !important;
    height: 2.3rem !important;
    width: 2.3rem !important;
    border-radius: 50% !important;
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
    line-height: 1rem !important;
    margin-left: 0.8rem !important;
}
