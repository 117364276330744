@import '../variables.scss';

@font-face {
    font-family: Montserrat-Medium;
    src: url(../../../assets/fonts/Montserrat/Montserrat-Medium.ttf) format('opentype');
}

.darkMode #table-container .dx-datagrid-content .dx-datagrid-table {
	border-collapse: separate;
    border-spacing: 0 0.1rem;
}

.lightMode #table-container .dx-datagrid-content .dx-datagrid-table {
	border-collapse: separate;
    border-spacing: 0;
}

.grid-table .dx-datagrid .dx-column-lines > td {
    border: none;
    padding-left: 3rem;
}

.grid-table .dx-datagrid-rowsview .dx-datagrid-content .dx-datagrid-table tbody  td:first-child {
    padding-left: 3rem !important;
}

.grid-table {
    .dx-datagrid-rowsview .dx-datagrid-content .dx-datagrid-table tbody > tr > td:first-child  {
		padding: 0px;
		border-radius:0 !important;
		width: 6rem;
	}

	.dx-datagrid-rowsview .dx-datagrid-content .dx-datagrid-table tbody > tr > td:last-child  {
		border-radius:0 !important;
	}
}


#grid-container {
    .dx-datagrid-headers {
        border: none;
        height: 14rem !important;

    }

    .dx-column-indicators {
        float: unset !important;
    }

    .dx-datagrid-header-panel {
        padding: 0 1.5rem;
        border-bottom: none;
        margin-bottom: 0rem !important;

        position: absolute;
        right: 0px;
        width: 20rem;
        z-index: 2;
    }

    td.header-topic-col.dx-cell-focus-disabled.dx-datagrid-drag-action {
        padding-top: 6.5rem !important;
      }
}

#table-container {
    #dxMyGrid {
        height: 100%;
        width: fit-content;
    }

    .dx-datagrid {
        background-color: transparent;
    }

    .dx-datagrid .dx-column-lines > td {
        border: none;
    }

    .dx-datagrid-headers {
        border: none;
        height: 5rem;

    }

	 .dx-datagrid-text-content {
		font-family: "CircularStd", sans-serif;
		font-size: 1.2rem !important;
		font-weight: 400 !important;
		text-transform: uppercase;
	}

    .dx-datagrid-rowsview .dx-row {
		height: 6rem;
		border-radius: 0.5rem;
    }

	.dx-datagrid-pager {
		border-top: none;
	}

    .dx-datagrid-rowsview {
        .dx-data-row {
            .dx-invalid-message {
                .dx-overlay-content {
                    padding-left: 0.5rem;
                    padding-right: 0.5rem;
                }
            }
        }
    }

    .dx-datagrid-headers + .dx-datagrid-rowsview {
        border: none;
        box-shadow: none;
    }

    .dx-datagrid-header-panel {
        border-bottom: 0.3rem;
        margin-bottom: 1.5rem;
    }

    .dx-datagrid-action {
        color: #5fa3d0;
        color: rgba(51, 51, 51, 0.7);
        font-size: 1.6rem;
        font-weight: 500;
        direction: rtl;
    }

    span.dx-sort-index-icon {
        display: none;
    }

    .dx-header-row .dx-sort-indicator.dx-text-content-alignment-right {
        text-transform: capitalize;
        margin: 0 0.3rem 0 0;
    }

    .dx-sort-down:before {
        font-family: 'Font Awesome 6 Pro', sans-serif;
        content: '\f063';
        font-size: 1.5rem;
        vertical-align: -15%;
        margin-left: 0.3rem;
    }

    .dx-sort-up:before {
        font-family: 'Font Awesome 6 Pro', sans-serif;
        content: '\f062';
        font-size: 1.5rem;
        vertical-align: -5%;
        margin-left: 0.3rem;
    }

    .dx-column-indicators {
        float: unset !important;
    }

	.dx-datagrid-borders  {
		border-top-left-radius: 1.5rem;
		border-top-right-radius: 1.5rem;
	  }

	  .dx-datagrid-rowsview .dx-datagrid-content .dx-datagrid-table tbody > tr > td:first-child  {
		padding: 0px;
		 border-radius: 0.6rem 0 0 0.6rem;
		width: 6rem;
	}

	.dx-datagrid-rowsview .dx-datagrid-content .dx-datagrid-table tbody > tr > td:last-child  {
		border-radius: 0 0.6rem 0.6rem 0;
	}

    .dx-datagrid-rowsview .dx-datagrid-content .dx-datagrid-table tbody .dx-row > td {
        font-size: 1.4rem;
        // color: #333333;
        height: 5rem;
        vertical-align: middle;
        overflow: visible;
    }

    .dx-datagrid-focus-overlay {
        border: 0;
    }

    .dx-editor-cell .dx-texteditor {
        padding-right: 1rem;
        background-color: transparent;
    }

    .dx-icon-edit:before {
        font-family: 'Font Awesome 6 Pro', sans-serif;
        content: '\f044';
    }

    .dx-icon-trash:before {
        font-family: 'Font Awesome 6 Pro', sans-serif;
        content: '\f2ed';
    }

    .dx-icon-save:before {
        font-family: 'Font Awesome 6 Pro', sans-serif;
        content: '\f0c7';
    }

    .dx-icon-cancel:before {
        font-family: 'Font Awesome 6 Pro', sans-serif;
        content: '\f2ea';
    }

    .dx-toolbar {
        background-color: #f9f9fc;
    }

	.dx-toolbar-items-container {
		height: 6rem;
	}

	.dx-texteditor-input-container {
		display: flex;
		flex-direction: row;
		justify-content: space-between;
		align-items: center;
	}

    .dx-datagrid-search-panel {
        margin: 0;
        border-radius: 0;
        border: none;
        background-color: #f9f9fc;
		width: 43.5rem !important;
		min-width: 10rem;

        &.dx-searchbox {
            .dx-icon-search {
                font: 2.1rem 'Font Awesome 5 Pro', sans-serif;
				top: unset;
				margin-top: unset;
				font-size: 1.4rem;
				position: absolute;
    			align-items: center;
				right: 0px;

                &:before {
                    content: '\f002';
                    color: #46a4d4;
                }
            }

            .dx-texteditor-input {
                padding-left: 1rem;
            }

            .dx-placeholder {
				font-family: "CircularStd", sans-serif;
				font-size: 1.4rem;
				font-style: italic;
				letter-spacing: normal;
				color: #7e8997;
				line-height: 1.8;

                &:before {
                    padding-left: 1rem;
                }
            }
        }
    }

    #icon {
        &.dx-template-wrapper {
            // border-right: 2px solid rgba(51, 51, 51, 0.1);
            margin-right: 1rem;
            display: flex;
            justify-content: center;
            align-items: center;
			height: 3rem;
    		width: 9rem;
        }
    }
}

.darkMode #table-container #icon.dx-template-wrapper {
	border-right: 0.2rem solid rgba(236, 244, 255, 0.2);
}

.lightMode #table-container #icon.dx-template-wrapper {
	border-right: 0.2rem solid #ecf4ff;
}
.lightMode .grid-table  td {
        font-family: "CircularStd", sans-serif;
        font-size: 1.4rem !important;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        text-align: left;
        color: #47576b !important;
        opacity: 1 !important;

    .col-snakeId {
        color: #46a4d4;
        font-size: 1.4rem !important;
    }
    .col-name {
        font-family: "CircularStd", sans-serif;
        font-size: 1.4rem;
        font-weight: bold !important;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        text-align: left;
        color: #47576b;
        opacity: 1 !important;
    }
}

#table-container .dx-datagrid-rowsview .dx-row-focused.dx-data-row .dx-command-edit:not(.dx-focused) .dx-link, .dx-datagrid-rowsview .dx-row-focused.dx-data-row > td:not(.dx-focused), .dx-datagrid-rowsview .dx-row-focused.dx-data-row > tr > td:not(.dx-focused) {
	color: #fff !important;

    .col-snakeId, .col-name {
        color: white !important;
    }

	#resourceTemplate a, #subscriptionTemplate {
		color: #fff !important;
	}
}

#table-container .dx-datagrid-rowsview .dx-row-focused {
	.label-name, .label-department, .label-entities, .label-status, .label-criticity, .label-snakeId {
		color: white !important;
	}
}

.lightMode .grid-table .dx-datagrid-rowsview .dx-datagrid-content .dx-datagrid-table tbody .dx-row > td {
    color: #7e8997;
    border: none !important;
    border-bottom: solid 1px #ecf4ff !important;
    border-left: none;
    border-right: none;

    #resourceTemplate a {
        color: #46a4d4 ;
    }

    #subscriptionTemplate {
        color: black  ;
    }
}

.lightMode #table-container {
	 .dx-datagrid-text-content {
        opacity: 0.5;
         font-family: "CircularStd", sans-serif !important;
        font-size: 1.2rem !important;
        font-weight: 500 !important;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        text-align: left;
        color: #47576b !important;
	}

	// .dx-datagrid-rowsview .dx-row {
	// 	background-color: white;
	// }

     .dx-datagrid-rowsview .dx-datagrid-content .dx-datagrid-table tbody .dx-row-focused > td {
        color: white !important;
    }

	.dx-datagrid-rowsview .dx-datagrid-content .dx-datagrid-table tbody .dx-row > td {
		color: #7e8997;
		border: solid 0.1rem #ecf4ff;
		border-left: none;
		border-right: none;

		#resourceTemplate a {
			color: #46a4d4 ;
		}

		#subscriptionTemplate {
			color: black  ;
		}
	}

	.dx-page-indexes {
		color: black;
	}
}

.darkMode #table-container {
	 .dx-datagrid-text-content {
        opacity: 0.5;
         font-family: "CircularStd", sans-serif !important;
        font-size: 1.2rem !important;
        font-weight: 500 !important;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        text-align: left;
        color: #fff !important;
	}

	.dx-datagrid-rowsview .dx-row {
		background-color: #000131;
		// border-bottom: 0.1rem solid #3a4b71;
		// border: none;
	}

	.dx-datagrid-rowsview .dx-datagrid-content .dx-datagrid-table tbody .dx-row > td {
		color: #fff;

		#resourceTemplate a {
			color: #46a4d4 ;
		}

		#subscriptionTemplate {
			color: white  ;
		}

		#hostingProviderNameTemplate, #typeNameTemplate, #parentNameTemplate, #relatedApplicationsTemplate {
			opacity: 0.7;
		}
	}
	.dx-page-indexes {
		color: white;
	}
}




.application {
    .dx-datagrid-headers {
        border-bottom: none;
        color: rgba($color: $slate, $alpha: 0.5);
        font-weight: 500;

        .dx-datagrid-table {
            .dx-row {
                > td {
                    background-color: $pale-grey-three;
                    border-top: none;
                    border-bottom: 0.2rem solid white;
                }
            }
        }

        + .dx-datagrid-rowsview {
            border-top: none;
        }
    }

    .dx-datagrid {
        background-color: $pale-grey-three;
        color: $slate;

        .dx-row {
            > td,
            > tr > td {
                background-color: $pale-grey-three;
                border-top: 0.2rem solid white;
                border-bottom: none;
            }
        }
    }

    .dx-datagrid-pager {
        border-top: 0.2rem solid white;
    }

    .dx-datagrid,
    .dx-datagrid-table {
        .dx-row {
            > td,
            > tr > td {
                padding-left: 1.5rem;
                border: none;
                border-bottom: 0.2rem solid white;
            }
            &.dx-datagrid-filter-row > td,
            &.dx-datagrid-filter-row tr > td {
                padding-left: 1rem;
                padding-right: 0.5rem;
                border: none;
                border-bottom: 0.2rem solid white;
            }
            &.dx-header-row > td {
                border: none;
            }
        }
    }

    .dx-datagrid-rowsview .dx-selection.dx-row:not(.dx-row-focused):hover > td {
        background-color: unset;
        color: white;
    }

    .dx-datagrid-table {
        .dx-row:not(.dx-header-row) {
            border-radius: 1rem;
            height: 2.5rem;
            line-height: 2.5rem;
            a {
                &:visited {
                    color: unset;
                }
                color: unset;
            }
            em {
                font-size: 1.8rem;
                cursor: pointer;
            }
            &.dx-selection {
                background-image: radial-gradient(circle at 81% 17%, #002f63, #0d59ad 78%);
                *,
                a {
                    &:visited {
                        color: unset;
                    }
                    color: white;
                    border: none;
                }
            }
            > td {
                background-color: unset;
            }
            &.clickable {
                cursor: pointer;
            }
        }
        .dx-row:not(.dx-header-row) td:first-child {
            border-top-left-radius: 1rem;
            border-bottom-left-radius: 1rem;
        }
        .dx-row:not(.dx-header-row) td:last-child {
            border-top-right-radius: 1rem;
            border-bottom-right-radius: 1rem;
        }
    }

    &.code-repo .dx-datagrid-table {
        .dx-row:not(.dx-header-row) {
            cursor: unset;
            &:hover,
            &.dx-selection {
                background-image: unset;
                *,
                a {
                    &:visited {
                        color: unset;
                    }
                    color: unset;
                    border: none;
                }
            }
        }
    }

    .custom-command-col {
        height: 2.5rem;

        em {
            height: 100%;
            display: flex;
            align-items: center;
        }
    }

    .dx-datagrid-focus-overlay {
        border: none;
    }

    .dx-datagrid-rowsview {
        z-index: 999;
        font-size: 1.6rem;
    }

    .dx-datagrid-filter-row td > div {
        margin: 1rem 0;
    }
}

#app-list-container {
    table {
        border-collapse: separate;
        border-spacing: 0 0.5em;
    }

    .dx-datagrid-rowsview .dx-datagrid-content .dx-datagrid-table tbody .dx-row > td {
        position: relative;
        overflow: hidden;
    }

    .dx-datagrid-pager {
        border: none;
        margin-top: 0.5rem;
    }

    .dx-page-size,
    .dx-page {
        font-size: 1.6rem;

        &.dx-selection {
            background-color: #c8d9e9;
            color: $primary-color;
            font-weight: bold;
        }
    }

    .dx-datagrid .dx-header-filter {
        font-family: 'Font Awesome 6 Pro', sans-serif;
    }

    .dx-datagrid .dx-header-filter::before {
        font-weight: bold;
        font-size: 1.6rem;
        content: '\f0b0';
    }

    .dx-datagrid .dx-header-filter-empty::before {
        font-weight: 500;
    }

    .dx-header-filter {
        cursor: pointer;
        margin-right: 0.7rem;
        color: rgba($primary-color, 0.7);

        &.dx-header-filter-empty {
            color: rgba($primary-color, 0.2);
        }
    }

    .dx-datagrid-rowsview {
        tr:hover,
        tr.dx-row-focused {
            background-image: radial-gradient(circle at 81% 17%, #002f63, hsl(212, 86%, 36%) 78%);

            .dx-template-wrapper #nameTemplate a span,
            .dx-template-wrapper #nameTemplate div span,
            .dx-template-wrapper span,
            .dropdown {
                color: white;
            }

            .dx-template-wrapper span #hint {
                color: #333333;
            }

            .dropdown-content li span {
                color: #333333;

                em {
                    opacity: 0.7;
                }
            }
        }
    }
}
.dx-freespace-row {
    display: none;
}

#headerSearchContainer .dx-texteditor-container {
    padding-right: 1.5rem;
}


#app-table-header .dx-texteditor-container {
    padding-right: 1.5rem;
}

.support-item {
    overflow: hidden;
    text-overflow: ellipsis;
}

#app-grid-container {
    .dx-datagrid .dx-header-filter {
        font-family: 'Font Awesome 6 Pro', sans-serif;
    }

    .dx-datagrid .dx-header-filter::before {
        font-weight: bold;
        font-size: 1.6rem;
        content: '\f0b0';
    }

    .dx-datagrid .dx-header-filter-empty::before {
        font-weight: 500;
    }

    .dx-header-filter {
        cursor: pointer;
        margin-right: 0.7rem;
        color: rgba($primary-color, 0.7);

        &.dx-header-fi.lightMode #table-container .dx-dataglter-empty {
            color: rgba($primary-color, 0.2);
        }
    }
}

.dx-datagrid .dx-datagrid-headers .dx-header-filter, .dx-datagrid .dx-datagrid-headers .dx-header-row > td {
    outline: 0;
    margin-right: 1rem;
}

.dx-button-has-icon .dx-button-content {
	padding: 0.8rem !important;
}

.lightMode #table-container .dx-datagrid-rowsview .dx-datagrid-content .dx-datagrid-table tbody > tr > td:first-child  {

		border: solid 0.1rem #ecf4ff;
		border-right: none;
}

.lightMode #table-container  .dx-datagrid-rowsview .dx-datagrid-content .dx-datagrid-table tbody > tr > td:last-child  {
		border: solid 0.1rem #ecf4ff;
		border-left: none;
}

.lightMode .grid-table .dx-datagrid-rowsview .dx-datagrid-content .dx-datagrid-table tbody > tr > td:first-child  {

    border: none !important;
    border-bottom: solid 1px #ecf4ff !important;
    border-right: none;
}

.lightMode .grid-table  .dx-datagrid-rowsview .dx-datagrid-content .dx-datagrid-table tbody > tr > td:last-child  {
    border: none !important;
    border-bottom: solid 1px #ecf4ff !important;
    border-left: none;
}