@use '@angular/material' as mat;
@import '@angular/material/theming';
@import '@angular/material/prebuilt-themes/deeppurple-amber.css';
@import 'assets/styles/fontawesome';

/* You can add global styles to this file, and also import other style files */
@import 'assets/styles/global';
@import 'assets/styles/fonts';
@import 'assets/styles/chips';
@import 'assets/styles/form';
@import 'assets/styles/typography';
@import 'assets/styles/admin';
@import 'assets/styles/drawer';

// Skin DevEtrem
@import 'assets/styles/dev-extrem-skin/accordion';
@import 'assets/styles/dev-extrem-skin/button';
@import 'assets/styles/dev-extrem-skin/data-grid';
@import 'assets/styles/dev-extrem-skin/grid-app';
@import 'assets/styles/dev-extrem-skin/dropdown';
@import 'assets/styles/dev-extrem-skin/file-uploader';
@import 'assets/styles/dev-extrem-skin/gallery';
@import 'assets/styles/dev-extrem-skin/popup';
@import 'assets/styles/dev-extrem-skin/scrollbar';
@import 'assets/styles/dev-extrem-skin/switch';
@import 'assets/styles/dev-extrem-skin/tagbox';
@import 'assets/styles/dev-extrem-skin/text-editor';
@import 'assets/styles/dev-extrem-skin/tooltip';
@import 'assets/styles/dev-extrem-skin/context-menu';

@import '@angular/cdk/overlay-prebuilt';

// COULDN'T FIND OTHER WAY TO OVERRIDE ....
.dashboard-main .dx-show-clear-button .dx-icon-clear {
    color: white;
}

// Define a custom mixin that takes in the current theme
@mixin light-theme-grabber($theme) {
  // Parse the theme and create variables for each color in the pallete
  $primary: map-get($theme, primary);
  $accent: map-get($theme, accent);
  $warn: map-get($theme, warn);
  // Create theme specfic styles


    .primary {
        background-color: white;
        color: #47576b;
    }

    .onPrimary{
        color: #47576b;
    }

	.onLabel {
		color: #47576b;
	}

	
	.secondary {
		background-color: white;
		box-shadow: 0 1px 9px 0 rgba(164, 192, 234, 0.5);  

		button {
			background-color: white;
			color: #47576b;
		}

		button.selected{
			background-color: #ecf4ff !important;
		}

		button:hover {
			background-color: rgba(236,244,255, 0.5);
		}
	}

	.onSecondary {
		color: #225297;
	}

	.tertiary {
		background-color: white;
        color: #47576b;


		.step-index {
			color: #47576b;
		}
	}

	.alternative-tertiary {
		border: solid 0.1rem #ecf4ff;
		background-color: #f9fbfd;
        color: #47576b;

		.field-label {
			font-family: "CircularStd", sans-serif;
			font-size: 1.4rem !important;
			font-weight: 500 !important;
			color: #47576b !important;
			text-transform: capitalize !important;
			opacity: 1 !important;
		}

		.edit-table .dx-texteditor-input {
			font-family: "CircularStd", sans-serif;
			font-size: 1.2rem;
			color: #47576b;
		}

		.edit-table .fieldset-horizontal .field-label{
			color: rgba(51, 51, 51, 0.7) !important;
			opacity: 0.7 !important;
			font-family: Montserrat, sans-serif;
			font-size: 1.2rem !important;
			font-weight: 500 !important;
			text-transform: uppercase !important;
		}

		.table-application .dx-datagrid-headers .dx-header-row {
			font-family: Montserrat, sans-serif;
			font-size: 12px !important;
			font-weight: 500 !important;
			color: rgba(51, 51, 51, 0.7) !important;
			text-transform: uppercase !important;
			opacity: 0.5 !important;
			height: 4rem !important;
		}

		.field-value{
			color: #7e8997;
		}

		.logo-upload {
			background-color: white;
		}
	}

	.onTertiary {
		color:#46a4d4;
	}

	.quaternary {
		background-color: white;
        color: #47576b;
		border: solid 0.1rem #ecf4ff !important;

		.button-search-type {
			&.active {
				color: white;
				background-image: linear-gradient(155deg, #46a4d4 1%, #5486ce 86%);
			}
		}

		.dx-texteditor.dx-editor-outlined {
			background: none !important;
			border: none !important;
		}

		.dx-texteditor-input {
			color:#47576b;
		}
		.dx-dropdowneditor-button .dx-button-content {
			color: #6ea2cc;
		}

		.edit-queries {
			border: solid 0.1rem #ecf4ff;
		}
	}

	.headerSearchContainer {
		border: solid 0.1rem #ecf4ff;
		background-color: rgba(255, 255, 255, 0.7);
	}

	.tableContainer {
		border: solid 0.1rem #ecf4ff;
		background-color: rgba(255, 255, 255, 0.7);


		.dx-scrollable-content {
			background-color: relative;
		}
	}

	.mgt-card-content {
        .toggle-container {
            button {
                background-color: inherit;
            }

            button:hover {
                background-color: white; 
            }
        }
		.toggle-container:hover {
			background-color: #ecf4ff;
		}
	}
    .mgt-card-header {
        border-bottom: 1px solid #ecf4ff;
    }
    .mgt-card-footer {
        border-top: 1px solid #ecf4ff;
    }

	.main-menu-container {
		border-right: 1px solid #ECF4FF;
	}

	.menu-link {
    	.icon {
			color: #728aa4;
		}
	}

	.menu-link.active,.menu-link.activeLong, .menu-link.activeShort, .menu-link:hover {
		background-image: linear-gradient(to bottom, #5fa3d0, #5486ce 98%);

		.icon {
			color: white;
			// opacity: 1;
		}
	}

  .accent {
	background-color: mat.get-color-from-palette($accent, '500');
	color: mat.get-color-from-palette($accent, '500-contrast');
  }

  .warn {
    background-color: mat-color($warn);
  }


  .fivenary {
	border: solid 0.1rem #ecf4ff;
	background-color: rgba(255, 255, 255, 0.7);
  }


  	.sixnary {
		border: solid 0.1rem #ecf4ff;
		background-color: #f9fbfd;
	}

	.sevenary {
		border: solid 0.1rem #ecf4ff;
		background-color: #fff;
	}

	.separationLine {
		border: solid 0.1rem #ecf4ff;
  		background-color: #fff;
	}

	#table-container .dx-toolbar {
		background-color: rgba(255, 255, 255, 0);
        color: #47576b;
	}

	#table-container .dx-datagrid-search-panel {
		border-radius: 3px;
		border: solid 0.1rem #ecf4ff;
		background-color: #fff;

		input {
			color: #47576b;
		}
	}

	.eightnary {
		color: #7e8997;
	}

	.nineary {
		background-color: white;
		border: none;
	}

	.query-action-button {
		background-image: linear-gradient(136deg, #46a4d4 3%, #5486ce 101%) !important;
		border: none !important;
		color: white !important; 
	}

	.query-list {
		background: linear-gradient(#fdfcff, #dee3f7);
		color: #47576b;

		.unactive-query .dx-texteditor-input {
			color: #333;
		}

		.active-query .dx-texteditor-input {
			color: white;
		}

		.dx-texteditor-input {
			color: #333;
		}

		.sharedQuery-contact .dx-texteditor-input {
			color: #47576b;
			font-size: 1.6rem;
    		font-weight: 500;
		}

		.sharedQuery-contact .dx-dropdowneditor-icon {
			background-color: rgba(255, 255, 255, 0);
			color: #46a4d4;
		}
	}

	.app-version {
		color: #47576b;
	}

	.edit-table .body-table {
        .fieldset-item {
            border: solid 0.1rem #ecf4ff;
            background-color: #fff;
        }
    }

	.table-edit-keyDate {
		border: solid 0.1rem #ecf4ff;
		background-color: #fff;
	}
}

// DARK

@mixin dark-theme-grabber($theme) {
  // Parse the theme and create variables for each color in the pallete
  $primary: map-get($theme, primary);
  $accent: map-get($theme, accent);
  $warn: map-get($theme, warn);

  .primary {
    background-color: #000131;
    color: white;
  }

  .onPrimary {
    color: white;

    .dx-filterbuilder .dx-filterbuilder-group .dx-filterbuilder-text {
      color: white;
    }
  }

  .onLabel {
    color: white;
  }

  .secondary {
    border: solid 0.1rem rgba(236, 244, 255, 0.1);
    background-color: #33345a;
    box-shadow: none;

    button {
      background-color: #33345a;
      color: white !important;
    }

    button.selected {
      background-color: rgba(255,255,255,0.2) !important;
    }

    button:hover {
      background-color: rgba(255,255,255,0.1);
    }
  }

  .onSecondary {
    color: white;
  }

  .tertiary {
    border-radius: 5px;
    border: solid 0.1rem rgba(236, 244, 255, 0.1);
    background-color: rgba(255, 255, 255, 0.2);
    color: white;

    .step-index {
      color: #fff;
    }
  }

  .alternative-tertiary {
    border-radius: 5px;
    border: solid 0.1rem rgba(236, 244, 255, 0.1);
    background-color: rgba(255, 255, 255, 0.2);
    color: white;

    .field-label {
      font-family: "CircularStd", sans-serif !important;
      font-size: 1.4rem !important;
      font-weight: 500 !important;
      color: rgba(255, 255, 255, 0.7) !important;
      text-transform: capitalize !important;
      opacity: 1 !important;
    }

    .field-more {
      color: rgba(255, 255, 255, 0.7) !important;
    }

    .edit-table .dx-texteditor-input {
      font-family: "CircularStd", sans-serif;
      font-size: 1.2rem;
      color: white;
    }

    .edit-table .fieldset-horizontal .field-label {
      color: white !important;
      opacity: 0.5 !important;
      font-family: Montserrat, sans-serif;
      font-size: 1.2rem !important;
      text-transform: uppercase !important;
      font-weight: 500 !important;
    }

    .table-application .dx-datagrid-headers .dx-header-row {
      font-family: Montserrat, sans-serif;
      font-size: 12px !important;
      font-weight: 500 !important;
      color: white !important;
      text-transform: uppercase !important;
      opacity: 0.7 !important;
      height: 4rem !important;
    }

    .field-value {
      color: white;
    }

    .logo-upload {
      background-color: #465672;
    }
  }

  .quaternary {
    border-radius: 5px;
    border: solid 0.1rem rgba(236, 244, 255, 0.1) !important;
    background-color: rgba(255, 255, 255, 0.2);
    color: white;

    .button-search-type {
      &.active {
        background-image: linear-gradient(155deg, #46a4d4 1%, #5486ce 86%);
      }
    }

    .dx-texteditor.dx-editor-outlined {
      background: none !important;
      border: none !important;
    }

    .dx-texteditor-input {
      color: white;
    }

    .dx-dropdowneditor-button .dx-button-content {
      color: #6ea2cc;
    }

    .edit-queries {
      border: solid 0.1rem rgba(236, 244, 255, 0.1);
    }

    .dx-scrollview-content {
      color: red !important;
    }
  }

  .headerSearchContainer {
    border: solid 0.1rem rgba(236, 244, 255, 0.1);
    background-color: rgba(255, 255, 255, 0.2);
  }

  .tableContainer {
    border: solid 0.1rem rgba(236, 244, 255, 0.1);
    background-color: rgba(255, 255, 255, 0.2);

    .dx-scrollable-content {
      background-color: relative;
    }
  }

  .onTertiary {
    color: #46a4d4;
  }

  .mgt-card-content {
    .toggle-container {
      button {
        background-color: rgba(255, 255, 255, 0);
      }

      button:hover {
        background-color: rgba(255, 255, 255, 0.1);
      }
    }

    .toggle-container:hover {
      background-color: rgba(255,255,255,0.1);
    }
  }

  .mgt-card-header {
    border-bottom: 1px solid rgba(236, 244, 255, 0.2);
  }

  .mgt-card-footer {
    border-top: 1px solid rgba(236, 244, 255, 0.2);
  }


  .main-menu-container {
    border-right: 1px solid rgba(236,244,255, 0.2);
  }

  .menu-link {
    .icon {
      opacity: 0.5;
      color: #fff;
    }
  }

  .menu-link.active, .menu-link.activeLong, .menu-link.activeShort, .menu-link:hover {
    background-image: linear-gradient(to bottom, #5fa3d0, #263c5c 98%);

    .icon {
      color: white;
      // opacity: 1;
    }
  }

  .accent {
    background-color: mat.get-color-from-palette($accent, '500');
    color: mat.get-color-from-palette($accent, '500-contrast');
  }

  .warn {
    background-color: mat-color($warn);
  }

  .loading {
    background-color: red
  }

  .fivenary {
    border: solid 0.1rem rgba(236, 244, 255, 0.1);
    background-color: rgba(255, 255, 255, 0.2);
  }

  .sixnary {
    background-color: #0e2f5f;
    border: none;
  }

  .sevenary {
    background-color: #000131;
    border: none;
  }

  .separationLine {
    border: none;
    background-color: rgba(215, 226, 237, 0.1);
  }

  #table-container .dx-toolbar {
    background-color: rgba(255, 255, 255, 0);
    color: white !important;
  }

  #table-container .dx-datagrid-search-panel {
    border-radius: 3px;
    border: solid 0.1rem rgba(236, 244, 255, 0.1) !important;
    background-color: rgba(255, 255, 255, 0.2);
    color: white !important;

    input {
      color: white !important;
    }
  }

  .eightnary {
    color: white;
  }

  .nineary {
    background-color: #071848;
    box-shadow: 0 4px 11px 0 #0d284f;
    border: solid 0.1rem #0e2f5f;
  }

  .query-action-button {
    background-image: linear-gradient(136deg, #46a4d4 3%, #5486ce 101%) !important;
    border: none !important;
    color: white !important;
  }

  .query-list {
    background: #202f5a;
    color: white;


    .unactive-query .dx-texteditor-input {
      color: white;
    }

    .dx-texteditor-input {
      color: white;
    }

    .sharedQuery-contact .dx-texteditor-input {
      font-size: 1.6rem;
      font-weight: 500;
      color: white;
    }

    .sharedQuery-contact .dx-dropdowneditor-icon {
      background-color: rgba(255, 255, 255, 0);
      color: #46a4d4;
    }

    .addAppButton {
      color: #fff;

      i {
        color: #fff !important;
      }
    }

    h2 {
      em {
        color: white !important;
      }
    }
  }



  .app-version {
    color: white;
  }

  .edit-table .body-table {
    .fieldset-item {
      border: solid 0.1rem rgba(236, 244, 255, 0.2);
      background-color: rgba(255, 255, 255, 0.1);
    }
  }

  .table-edit-keyDate {
    border: solid 0.1rem rgba(236, 244, 255, 0.2);
    background-color: rgba(255, 255, 255, 0.1);
  }

  .platformView {
    background-color: #4a5b78 !important;
  }

  .menu-close {
    background-color: #0e2f5f;
  }


  .popupDepartment .dx-popup-normal {
    background-color: red !important;
  }
}
