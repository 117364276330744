@import 'src/assets/styles/variables.scss';

.chip-set {
    display: flex;
    flex-wrap: wrap;
}

.chip {
    padding: 0.5rem 1rem;
    margin-right: 5px;
    color: white;
    font-size: 1.2rem;
    margin-bottom: 5px;
    border-radius: 3px;
    border: solid 0 #979797;
    background-image: linear-gradient(147deg, #5486ce 25%, #46a4d4 113%);
    height: 2.6rem;
}
