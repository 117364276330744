@import '../variables.scss';

.dx-texteditor {
    // &.dx-editor-outlined {
    //     // border-color: $grey-2;
    //     border-radius: 5px;
    //     // box-shadow: 0 2px 9px 0 $grey-e;
    // }
}

// .dx-texteditor-input {
//     min-height: 45px;
// }

// .new-query-wrapper {
//     input.dx-texteditor-input {
//         height: 40px;
//         min-height: 40px;
//     }
// }

// .map-search {
//     .dx-texteditor.dx-editor-outlined {
//         border: none;
//         box-shadow: none;
//     }
// }

.search-input-container .dx-texteditor-buttons-container .dx-button-danger  {
	opacity: 0.5;
}

.search-input-container .dx-texteditor-buttons-container {
	.dx-button-text {
		position: relative;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding: 0.4rem 0.8rem;
    border-radius: 0.2rem;
    background-image: radial-gradient(circle at 50% 50%, #46a4d4, #225297 139%);
    cursor: pointer;
    color: white;
    font-size: 1.2rem;
    font-weight: bold;
	font-family: "CircularStd", sans-serif;
	height: 2.2rem;
	width: 3.3rem;
	}
}

.search-input-container .dx-texteditor-buttons-container:last-child {
    .dx-clear-button-area {
        right: 0px;
        position: absolute;
    }

	.dx-button-text {
		position: relative;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding: 0.4rem 0.8rem;
    border-radius: 0.2rem;
    background-image: radial-gradient(circle at 50% 50%, #46a4d4, #225297 139%);
    cursor: pointer;
    color: white;
    font-size: 1.2rem;
    font-weight: bold;
	font-family: "CircularStd", sans-serif;
	height: 2.2rem;
	width: 3.3rem;
	}
}

.search-apps-input-container .dx-texteditor-container .dx-texteditor-buttons-container:first-child, .container-dashboard .search-input-container .dx-texteditor-container .dx-texteditor-buttons-container:first-child  {
		padding-right: 1.5rem;
		height: 3rem;
		display: flex;
		flex-direction: row;
		align-items: center;
		justify-content: center;
		margin: 1rem 0rem 1rem 0rem;
}

.search-apps-input-container .dx-texteditor-buttons-container:first-child {

    &::before {
        content: 'Only my applications';
        padding: 0 1rem 0 1.5rem;
        font-size: 1.4rem;
        line-height: 3.1rem;
        height: 3rem;
		font-family: "CircularStd", sans-serif;
    }
}

.lightMode .container-dashboard .search-input-container .dx-texteditor-container .dx-texteditor-buttons-container:first-child {

    border-right: solid 2px #ecf4ff;
}

.darkMode .container-dashboard .search-input-container .dx-texteditor-container .dx-texteditor-buttons-container:first-child {

    border-right: solid 2px rgba(236, 244, 255, 0.2);
}



.lightMode .search-apps-input-container .dx-texteditor-container .dx-texteditor-buttons-container:first-child {

		border-right: solid 2px #ecf4ff;
}

.darkMode .search-apps-input-container .dx-texteditor-container .dx-texteditor-buttons-container:first-child {

		border-right: solid 2px rgba(236, 244, 255, 0.2);
}

.lightMode .search-apps-input-container .dx-texteditor-buttons-container:last-child {

    &::before {
		color: #7e8997;
		border-left: solid 2px #ecf4ff;
    }
}

.darkMode .search-apps-input-container .dx-texteditor-buttons-container:last-child {

    &::before {
		color: white;
		opacity: 0.5;
		border-left: solid 2px rgba(236, 244, 255, 0.2);
    }
}

#container-applications  .search-apps-input-container .dx-texteditor-buttons-container:last-child  .dx-clear-button-area {
    right: 13rem;
}


.search-apps-input-container .dx-texteditor-buttons-container:last-child {
    .dx-clear-button-area {
        right: 150px;
        position: absolute;
    }

    &::before {
        content: 'Extended';
        padding: 0 1rem 0 1.5rem;
        font-size: 1.4rem;
        line-height: 3.1rem;
        height: 3rem;
		font-family: "CircularStd", sans-serif;
    }
}

#container-dashboard .search-apps-input-container .dx-texteditor-input-container {
    margin-right: 2.4rem;
}

.search-apps-input-container .dx-button.dx-button-has-icon.dx-button-mode-text.dx-button-normal.dx-widget {
    margin-left: -3.4rem;
    position: absolute;

    em {
        font-size: 1.6rem;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        letter-spacing: normal;
        text-align: left;
        color: #6ea2cc;
    }
}

.search-input-container .dx-texteditor-buttons-container > .dx-button > .dx-button-content {
	min-height: 2.2rem;
    min-width: 3.3rem;
    padding: 0px;
    border-radius: 0.2rem;
}

#page-container #dashboard-container .search-input-container {
    background-color: rgba(255, 255, 255, 0.5);
    width: 600px;
    height: 5rem;
    border-radius: 4px;
    box-shadow: unset;
    margin: 0;
    border: none;

    .dx-texteditor-buttons-container .dx-button {
        width: 5rem;
        opacity: 1;

        em {
            color: white;
            opacity: 0.5;
        }

        &.dx-state-hover {
            background-color: unset;
        }
    }

    .dx-texteditor-container .dx-texteditor-buttons-container {
        padding-right: 5px;
    }
    // .dx-texteditor-input {
    //     min-height: unset;
    // }

    input {
        background-color: transparent;
        width: 100%;
        border: none;
        border-color: rgba(209, 209, 209, 0.7);
        padding-left: 8px;
        color: white;
        font-size: 1.8rem;
    }

    .dx-button-mode-contained {
        background-color: transparent;
    }

    .dx-placeholder {
        color: white;
    }

    .dx-texteditor-buttons-container:last-child {
        &::before {
            border-left: 2px solid #eceef5;
            opacity: 1;
            color: white;
        }
    }
}

.list-wrapper {
    .unactive-query .dx-textbox {
        input {
            cursor: pointer;
            // color: #333;
            border: none;
        }

        .dx-placeholder {
            color: #999;
        }
    }
    input {
        border-bottom: 1px solid white;
        // color: white;
        padding-bottom: 0;
        min-height: 3rem;
        border-radius: 0;
    }

    .dx-placeholder {
        color: white;
    }

    .dx-textbox {
        border: none;
        background-color: transparent;
        box-shadow: none;
    }
}


.headerSearchContainer .dx-texteditor.dx-editor-outlined {
	height: 100%;
	border-radius: 4px 0 0 4px;
}