.application {
    .dx-tag-container {
        &.dx-texteditor-input-container {
            .dx-texteditor-input {
                min-height: 41px;
            }
        }
    }

    .dx-tag-content {
        border-radius: 17.5px;
        margin: 8px 0 0 8px;
        padding: 7px 25px 7px 13px;
        background-color: $primary-color;
        color: white;
        font-size: 12px;
        display: flex;
        align-items: center;

        &.hidden {
            display: hidden;
        }
    }

    .dx-tag-remove-button {
        right: 5px;
        &:after,
        &:before {
            background: white;
            width: 1px;
        }

        &:after {
            right: 4px;
            margin-top: 0px;
            width: 11px;
            height: 1px;
        }
    }
}

.dx-tagbox {
    min-width: 150px;
}
