.addNew {
    border-radius: 4px;
    box-shadow: 0 2px 4px 0 #cccccc;
    background-image: radial-gradient(circle at 83% 62%, #00549c, #002a63 67%);
    color: white;
    border: none;

    span {
        line-height: unset;
    }

    &:hover {
        opacity: 0.9;
    }
}

.ref-table-container {
    padding: 2.5rem;
    
    height: calc(100vh - 5rem);
    flex-grow: 1;
    overflow: hidden;
	border-radius: 1rem;
	margin-left: 2.5rem;

}

.ref-table-container td {
    padding-left: 4rem !important;
}

.ref-table-container td.dx-command-edit-with-icons {
    padding-left: 0rem !important;
}

#table-container {
    height: 100%;
    flex-grow: 1;
    overflow: hidden;
}

.ref-table-container,
#table-container {
    display: flex;
    flex-direction: column;

    #ref-table-header .search-input-container {
        width: 100%;
    }
}

#ref-table-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 3rem;
}

#table-container .dx-datagrid-headers + .dx-datagrid-rowsview {
    z-index: 1;
}

#admin-container {
    .dx-datagrid-content .dx-datagrid-table {
        border-collapse: separate;
        border-spacing: 0 0.5em;
    }

    #table-container .dx-datagrid-headers + .dx-datagrid-rowsview {
        box-shadow: unset;
    }

    #table-container .dx-datagrid-rowsview .dx-row {
        box-shadow: unset;
        border-radius: 4px;
    }

    #table-container tr td:first-child {
        padding-left: 20px;
    }

    #table-container .dx-datagrid-rowsview .dx-datagrid-content .dx-datagrid-table tbody .dx-row:hover {
        background-image: radial-gradient(circle at 81% 17%, #002f63, hsl(212, 86%, 36%) 78%);
        td {
            color: white;
        }

        .dx-field-item-label-text {
            color: white;
        }

        .dx-texteditor.dx-editor-outlined {
            box-shadow: unset;
        }
    }

    #table-container .dx-datagrid-search-panel {
        background-color: white;
        border-radius: 4px;
    }

    #table-container .dx-toolbar {
        background-color: unset;
    }

    .dx-datagrid-table .dx-row .dx-command-expand.dx-datagrid-group-space {
        padding-top: unset;
    }

    .dx-datagrid-pager {
        border: unset;
    }
}
