@import 'src/assets/styles/variables.scss';

.fieldset,
.fieldset-horizontal {
    display: flex;
    margin-bottom: 2.8rem;

    &:last-child {
        margin-bottom: unset;
    }
}

.fieldset {
    .field {
        &:not(:first-child) {
            padding-left: 2rem;
        }

        &:not(:last-child) {
            padding-right: 2rem;
        }
    }
}

.fieldset-horizontal {
    flex-direction: column;

    .field {
        display: flex;
    }

    .field-edit-line {
        border-radius: 0.5rem;
        border: solid 0.1rem #ecf4ff;
        height: 6rem;
    }

    .field-label {
        margin-bottom: 0;
        flex-basis: 20rem;
    }

    .field-value {
        flex-grow: 1;
    }
}

.field {
    flex: 1 1 0;
}

.field-label {
    // color: rgba($color: $slate, $alpha: 0.5);
    margin-bottom: 5px;
}

.field-value {
    color: $slate;
    font-size: 1.6rem;
    font-weight: 500;
}

.show_field {
	overflow: visible;
	height: auto;
	max-height: max-content !important;
}

.field-more {
  margin-top: 10px;
  font-size: 12px;
  font-weight: bold;
  font-style: normal;
  letter-spacing: normal;
  color: $black-3-a7;
  cursor: pointer;
  line-height: 20px;
}

.fieldset-item {
    display: flex;
    align-items: flex-end;
    color: $black-3-a7;
    flex-grow: 1;

    > .field-grow {
        flex: 1 1;
    }

    > .dx-button {
        height: 47px;
    }

    > *:not(:last-child) {
        padding-right: 2rem;
    }

    .field-label {
        margin-bottom: 5px;
    }
}

form {
    .field-label {
        // color: $slate;
    }
}

.switch-applicable-btn {
    margin-left: auto;
}

#admin-ref-container .search-input-container input {
    background-color: transparent;
}

.support-edit {
    .fieldset-item-support {
        width: 100%;
        margin-bottom: 5px;
    }
    .field {
        flex-direction: column;
    }

    .field-grow-4 {
        flex-grow: 4;
    }
    .field-grow-3 {
        flex-grow: 3;
    }
}

.search-input-hosting {
    input {
        padding-left: 45px;
    }
    .dx-placeholder {
        left: 35px;
    }
}

// .custom-item input {
//     font-weight: 500;
//     color: #47576b;
// }


#app-table-header #query-picker {
    &.dx-texteditor.dx-state-active.dx-editor-filled,
    &.dx-texteditor.dx-state-active.dx-editor-outlined,
    &.dx-texteditor.dx-state-active.dx-editor-underlined,
    &.dx-texteditor.dx-state-focused.dx-editor-filled,
    &.dx-texteditor.dx-state-focused.dx-editor-outlined,
    &.dx-texteditor.dx-state-focused.dx-editor-underlined,
    &.dx-editor-outlined {
        border: none;
        display: block;
        background-color: rgba(71, 87, 107, 0.1);
        border-radius: 8px;
        box-shadow: none;
    }

    .dx-dropdowneditor-button {
        color: #47576b;
        font-size: 1.6rem;
        .dx-button-content {
            line-height: 31px;
        }
    }
    .dx-texteditor-input {
        min-height: 0;
        padding-left: 12px;
    }

    .placeholder {
        input {
            opacity: 0.7;
            padding-top: 5px;
        }
    }
    .dx-texteditor-container {
        padding: none;
    }
}


.container-show_field {
		max-height: 5.8rem;
    	overflow: hidden;
    	min-height: 2.9rem;

		.chip {
			height: 2.6rem;
		}
}


.edit-table {
    .fieldset-item {
        display: flex;
        flex-direction: row;
        align-items: center;
        padding-left: 2rem;
        height: 4rem;
    }

    .field-label {
        // color: #47576b;
        font-size: 1.2rem;
        opacity: 0.5;
        text-transform: uppercase;
    }

    .body-table {
        .fieldset-item {
            border-radius: 5px;
            padding-left: 2rem;
            padding-right: 2rem;

            .dx-show-clear-button .dx-icon-clear {
                font-size: 1.4rem !important;
                line-height: 1.6rem !important;
            }
        }
    }
}
