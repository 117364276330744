.dx-gallery {
    .dx-gallery-nav-button-next,
    .dx-gallery-nav-button-prev {
        &:before {
            font-family: 'Font Awesome 6 Pro', sans-serif;
            font-size: 2rem;
            margin-top: -8px;
        }
    }

    .dx-gallery-nav-button-next {
        &:before {
            content: '\f054';
        }
    }

    .dx-gallery-nav-button-prev {
        &:before {
            content: '\f053';
        }
    }
}
