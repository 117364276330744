* {
    box-sizing: border-box;
    font-family: 'CircularStd', sans-serif;
}
button {
    border: none;
}

button:focus,
button:active {
    border: none;
    outline: none;
}

.hidden {
    display: none !important;
}

ul {
    margin: 0;
    padding: 0;
    display: flex;
    list-style: none;
}

.search-input-container {
    display: flex;
    align-items: center;

    em {
        font-size: 22px;
        color: #002f63;
        margin-right: 19px;
    }

    input {
        border: none;
        font-size: 1.5rem;
    }

    input::placeholder {
        font-size: 1.5rem;
    }

    input:focus {
        outline: none;
    }
}

.userAvatar {
    margin-right: 11px;
    border-radius: 50%;
    width: 26px;
    height: 26px;
}

.dx-toast-message {
    white-space: pre-line;
}

body::-webkit-scrollbar {
    width: 5px;
}

body::-webkit-scrollbar-track {
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}

body::-webkit-scrollbar-thumb {
    background-color: darkgrey;
    outline: 1px solid slategrey;
}

.dx-drawer-overlap.dx-drawer-right .dx-drawer-wrapper {
    padding-left: 2.5rem;
	padding-right: 2.5rem;
    .dx-drawer-content {
        overflow: auto;
        -ms-overflow-style: none; /* for Internet Explorer, Edge */
        scrollbar-width: none; /* for Firefox */
    }
}

@media (-webkit-min-device-pixel-ratio: 2) {
    .dx-drawer-overlap.dx-drawer-right .dx-drawer-wrapper { 
        padding-left: 0rem !important;
        padding-right: 2rem !important;
    }
}
  

.dx-drawer-overlap.dx-drawer-right .dx-drawer-wrapper .dx-drawer-content::-webkit-scrollbar {
    display: none; /* for Chrome, Safari, and Opera */
}
.dx-overlay-content.dx-resizable {
    overflow: visible;
}

.topic-accordion-title {
    background-color: transparent;
    border: none;
}

.notifyLoadPanelIndicator {
    border-top-left-radius: 0px !important;
    border-bottom-left-radius: 0px !important;
}


// NEW



html {
	font-size: 62.5% !important;

    /* Pour les écrans 4K */
    @media screen and (min-width: 2560px) {
        html {
        font-size: 100%; /* ajustez en fonction de vos besoins */
        }
        // @media (-webkit-min-device-pixel-ratio: 1.1) {
        //     html {
        //         font-size:57% !important;
        //         }
        // }
        
        // @media (-webkit-min-device-pixel-ratio: 1.25) {
        //     html {
        //         font-size:50% !important;
        //         }
        // }
        
        // @media (-webkit-min-device-pixel-ratio: 1.5) {
        //     html {
        //         font-size:41.5% !important;
        //         }
        // }
        
        // @media (-webkit-min-device-pixel-ratio: 1.75) {
        //     html {
        //         font-size: 40% !important;
        //         }
        // }
        
        // @media (-webkit-min-device-pixel-ratio: 2) {
        //     html {
        //         font-size:42% !important;
        //         }
        // }
    }

     /* Pour les écrans de résolution Full HD */
	@media only screen and (max-width: 2559px) {
		font-size: 75% !important;
	}

     /* Pour les écrans de résolution Full HD */
	@media only screen and (max-width: 1920px) {
		font-size: 62.5% !important;
	}

    // It will be applied to TABLET-LANDSCAPE  
	@media only screen and (max-width: 1200px) {
		font-size: 56.25% !important; // 1rem = 9px; 9px/16px = 56.25%
	}
	  
	// It will be applied to TABLET-PORTRAIT
	@media only screen and (max-width: 900px) {
		font-size: 50% !important; // 1rem = 8px; 8px/16px = 50%
	}
  
	// It will be applied to PHONE
	@media only screen and (max-width: 600px) {
		font-size: 37.5% !important; // 1rem = 6px; 6px/16px = 37.5%
	}
}


.dx-theme-generic-typography {
	font-size: 1.6rem;
}

.dx-overlay-shader {
	background-color: rgba(17, 22, 30, 0.7) !important;
}