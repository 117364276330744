@font-face {
    font-family: 'CircularStd';
    src: url(/assets/fonts/CircularStd/CircularStd-Book.ttf) format('truetype');
    font-weight: 400;
}

@font-face {
    font-family: 'CircularStd';
    src: url(/assets/fonts/CircularStd/CircularStd-BookItalic.ttf) format('truetype');
    font-weight: 400;
    font-style: italic;
}

@font-face {
    font-family: 'CircularStd';
    src: url(/assets/fonts/CircularStd/CircularStd-Medium.ttf) format('truetype');
    font-weight: 500;
}

@font-face {
    font-family: 'CircularStd';
    src: url(/assets/fonts/CircularStd/CircularStd-MediumItalic.ttf) format('truetype');
    font-weight: 500;
    font-style: italic;
}

@font-face {
    font-family: 'CircularStd';
    src: url(/assets/fonts/CircularStd/CircularStd-Bold.ttf) format('truetype');
    font-weight: 700;
}

@font-face {
    font-family: 'CircularStd';
    src: url(/assets/fonts/CircularStd/CircularStd-BoldItalic.ttf) format('truetype');
    font-weight: 700;
    font-style: italic;
}

@font-face {
    font-family: 'CircularStd';
    src: url(/assets/fonts/CircularStd/CircularStd-Black.ttf) format('truetype');
    font-weight: 900;
}

@font-face {
    font-family: 'CircularStd';
    src: url(/assets/fonts/CircularStd/CircularStd-BlackItalic.ttf) format('truetype');
    font-weight: 900;
    font-style: italic;
}
