@import '../variables.scss';

.dx-scrollable-scroll-content {
    background-color: $secondary-color;
}

.scroll-view-owners {
    .dx-scrollable-content {
        &:after,
        &:before {
            display: none;
        }
    }
}

.drawer-container {
    .dx-scrollbar-vertical {
        right: -20px;
    }
}
