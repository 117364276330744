@import '../variables.scss';

.dx-switch {
    &.dx-state-hover,
    &.dx-state-focused,
    &.dx-state-active,
    &.dx-state-focused.dx-state-active {
        .dx-switch-container {
            border-color: #46a4d4;
            background-color: $grey-2;
        }

        .dx-switch-handle {
            &:before {
                background-color: white;
            }
        }

        &.dx-switch-on-value {
            .dx-switch-container {
                background-color: #46a4d4;
            }

            .dx-switch-handle {
                &:before {
                    background-color: white;
                }
            }
        }
    }
}

.dx-switch-container {
    border-radius: 12.5px;
    background-color: $grey-2;
    transition: background-color 0.5s;
}

.dx-switch-handle {
    flex-basis: 18px;
    width: 18px;

    &:before {
        border-radius: 5rem;
        background-color: white;
    }
}

.dx-switch-on-value {
    .dx-switch-container {
        background-color: #46a4d4;
    }

    .dx-switch-handle {
        &:before {
            background-color: white;
        }
    }
}
