@use '@angular/material' as mat;
@include mat.core();
@import './styles';
@import '@angular/material/theming';

@import '~devextreme/dist/css/dx.dark.css';
@import '~devextreme/dist/css/dx.light.css';

$light-primary: mat.define-palette(mat.$grey-palette);
$light-accent: mat.define-palette(mat.$grey-palette);
$light-warn: mat.define-palette(mat.$red-palette);

$angular-light-theme: mat.define-light-theme(
  (
    color: (
    	primary: $light-primary,
    	accent:$light-accent,
		  warn: $light-warn,
    ),
  )
);

$dark-primary: mat.define-palette(mat.$gray-palette);
$dark-accent: mat.define-palette(mat.$gray-palette);
$dark-warn: mat.define-palette(mat.$red-palette);

$angular-dark-theme: mat.define-dark-theme(
  (
    color: (
      	primary: $dark-primary,
      	accent: $dark-accent,
    	  warn: $dark-warn,
    ),
  )
);

// @include mat.all-component-themes($angular-default-theme);
@include light-theme-grabber($angular-light-theme);

.darkMode {
  @include dark-theme-grabber($angular-dark-theme);
}

.lightMode {
	@include light-theme-grabber($angular-light-theme);
}